
            @import "app/styles/variables.scss";
            @import "app/styles/mixins.scss";
          
.latestApp{
  
  display: flex;
  flex-direction: column;
  gap: 20px;
  
  .latestCardWrapper{
    display: flex;
    flex-direction: column;
    gap: 20px;
    
    
    .latestCardHeader{
      padding-top: 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      
      .latestHeaderTitle{

        font-size: 16px;
        font-weight: 600;
        line-height: 19.09px;
        letter-spacing: 0.02em;
        color: #262626;
  
      }
      .latestHeaderLink{

        font-size: 16px;
        font-weight: 600;
        line-height: 19.09px;
        letter-spacing: 0.02em;
        color: #4794EF;
        text-decoration: none;
      }
      
    }
    
    .latestCardBlock{
      display: flex;
      flex-direction: row;
      gap: 10px;
      
      .latestCard{
        
        display: flex;
        flex-direction: column;
        gap: 20px;
        
        background: #FFFFFF;
        border-radius: 10px;
        width: 260px;
        height: 420px;
        padding: 20px;
        
        .latestCardTitle{
          font-size: 18px;
          font-weight: 600;
          line-height: 21.47px;
          letter-spacing: 0.02em;
          color: #242731
  
        }
        
        .latestCardDesc{
          display: flex;
          flex-direction: column;
          gap: 20px;
          
          .latestCardDescRow{
            display: flex;
            flex-direction: column;
            gap: 4px;
          }
          
          
          .cardDescTitle{
            
            font-size: 14px;
            font-weight: 500;
            line-height: 16.48px;
            letter-spacing: 0.02em;
            color: #96A8B8;
          }
          
          .cardDescValue{
            font-size: 16px;
            font-weight: 600;
            line-height: 19.09px;
            letter-spacing: 0.02em;
            color: #001317;
  
          }
          
          .new{
            color: #FC2E20;
          }
          
          .done{
            color: #03A65A;
          }
          
          .work{
            color: #FC8621;
          }
        }
        
      }
      
      
      .newCard{
        background: #FFFFFF;
        border-radius: 10px;
        width: 260px;
        height: 420px;
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    
  }
  
  
  
}
.btn{
  font-size: 14px!important;
  height: 40px!important;
}


@media (min-width: 1440px) {
  
  .latestHeaderLinkMob{display: none!important;}
  .btnMob{display: none!important;}
}
@media (max-width: 1439px) {
  .latestApp{
    
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 700px;
    margin: 0 auto;
    
    .latestCardWrapper{
      display: flex;
      flex-direction: column;
      gap: 20px;
      
      
      .latestCardHeader{
        padding-top: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        
        .latestHeaderTitle{
          
          font-size: 16px;
          font-weight: 600;
          line-height: 19.09px;
          letter-spacing: 0.02em;
          color: #262626;
          
        }
        .latestHeaderLink{
          display: none;
          font-size: 16px;
          font-weight: 600;
          line-height: 19.09px;
          letter-spacing: 0.02em;
          color: #4794EF;
          text-decoration: none;
        }

      }
      
      .latestCardBlock{
        display: flex;
        flex-direction: row;
        gap: 10px;
        width: 95vw;
        overflow: scroll;
        .latestCard{
          
          display: flex;
          flex-direction: column;
          gap: 20px;
          
          background: #FFFFFF;
          border-radius: 10px;
          width: 248px;
          min-width: 248px;
          height: 395px;
          min-height: 395px;
          padding: 16px;
          
          .latestCardTitle{
            font-size: 18px;
            font-weight: 600;
            line-height: 21.47px;
            letter-spacing: 0.02em;
            color: #242731
            
          }
          
          .latestCardDesc{
            display: flex;
            flex-direction: column;
            gap: 17px;
            
            .latestCardDescRow{
              display: flex;
              flex-direction: column;
              gap: 4px;
            }
            
            
            .cardDescTitle{
              
              font-size: 14px;
              font-weight: 500;
              line-height: 16.48px;
              letter-spacing: 0.02em;
              color: #96A8B8;
            }
            
            .cardDescValue{
              font-size: 16px;
              font-weight: 600;
              line-height: 19.09px;
              letter-spacing: 0.02em;
              color: #001317;
              
            }
            
            .new{
              color: #FC2E20;
            }
            
            .done{
              color: #03A65A;
            }
            
            .work{
              color: #FC8621;
            }
          }
          
        }
        
        
        .newCard{
          display: none;
          background: #FFFFFF;
          border-radius: 10px;
          width: 248px;
          height: 395px;
          padding: 16px;
          align-items: center;
          justify-content: center;
        }
      }
      
    }
    
    
    
  }
  .btn{
    font-size: 14px!important;
    height: 40px!important;
    display: none;
  }
  .btnMob{
    display: flex;
    font-size: 14px!important;
    height: 40px!important;
    max-width: 220px;
  }
  .latestHeaderLinkMob{
    display: flex;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.09px;
    letter-spacing: 0.02em;
    color: #4794EF;
    text-decoration: none;
    margin-left: auto;
  }
  
}
