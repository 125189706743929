
            @import "app/styles/variables.scss";
            @import "app/styles/mixins.scss";
          
.latestApp {
  
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  
  .latestCardWrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
    
    
    .latestCardHeader {
      padding-top: 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      
      .latestHeaderTitle {
        
        font-size: 16px;
        font-weight: 600;
        line-height: 19.09px;
        letter-spacing: 0.02em;
        color: #262626;
        
      }
      
      .latestHeaderLink {
        
        font-size: 16px;
        font-weight: 600;
        line-height: 19.09px;
        letter-spacing: 0.02em;
        color: #4794EF;
        text-decoration: none;
      }
      
    }
  }
  .latestAppTable{
  
  }
}
.btn{
  font-size: 14px!important;
  height: 40px!important;
}
.tableContainer{
  
  
  .table{
    border-collapse: separate;
    border-spacing: 0 10px;
    .tableHead{
      
      .tableHeadRow{
        
        height: 60px;
        
        
        //padding: 20px;
        //margin-bottom: 10px;
        
        .tableHeadCell{
          font-size: 16px;
          font-weight: 600;
          line-height: 19.09px;
          letter-spacing: 0.02em;
          text-align: left;
          color: #96A8B8;
          background: #FFFFFF;
          border: 0;
          padding: 20px 25px
        }
        
        .tableHeadCellFirst{
          border-bottom-left-radius: 10px;
          border-top-left-radius: 10px;
          padding: 20px 25px 20px 20px;
        }
        
        .tableHeadCellLast{
          border-bottom-right-radius: 10px;
          border-top-right-radius: 10px;
          padding: 20px 20px 20px 25px;
        }
        
        .statusColor{
        
        }
      }
      
      
    }
    
    .tableBody{
      
      .tableBodyRow{
        gap: 10px;
        height: 60px;
        
        
        .tableBodyCell{
          height: 60px;
          font-size: 16px;
          font-weight: 600;
          line-height: 19.09px;
          letter-spacing: 0.02em;
          color: #262626;
          padding: 0 25px;
          background: #FFFFFF;
        }
        
        .tableBodyCellFirst{
          border-bottom-left-radius: 10px;
          border-top-left-radius: 10px;
          padding: 0 25px 0 20px;
        }
        
        .tableBodyCellLast{
          border-bottom-right-radius: 10px;
          border-top-right-radius: 10px;
          padding: 0 20px 0 25px;
        }
      }
      
      
    }
    
    
    
    
  }
}

.waiting{
  color: #FC8621!important;
}
.apply{
  color: #03A65A!important;
}
.svgIcon{
  background: #4794EF!important;
  border-radius: 10px!important;
}

@media (max-width: 900px) {
  .tableCellMobile{
    display: none!important;
  }
}
@media (max-width: 1439px) {
  .tableContainer {
    height: 100%;
  }
  .table{
    min-width: unset!important;
  }
  .tableHeadCell{
    padding: 20px 25px
  }
  .tableHeadCellFirst{
    padding: 20px;
    max-width: 120px;
  }
  
  .tableHeadCellLast{
    padding: 20px;
  }
  .tableBodyCell{
    padding: 0 20px;
  }
  .tableBodyCellFirst{
    padding: 0 20px 0 20px;
    max-width: 120px;
  }
  .tableBodyCellLast{
    padding: 0 20px 0 20px;
  }
  .tableBodyCell{
    font-size: 14px;
  }
}
