
            @import "app/styles/variables.scss";
            @import "app/styles/mixins.scss";
          
.svgIcon{
  background: #4794EF!important;
  border-radius: 10px!important;
}
.agentsPage{
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.greenCheck{
  margin-right: 20px;
}
.greenBtn{
  width: 220px;
  margin-left: auto;
  > button {
    height: 40px;
    background-color: #03A65A;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.7px;
    letter-spacing: 0.02em;
    
    &:hover{
      background-color: #0c9756;
    }
    &:active{
      background-color: #10a05b;
    }
    &:focus{
      background-color: #10a05b;
    }
  }
}


.tableContainer{
  height: calc(100vh - 120px);
  
  .table{
    border-collapse: separate;
    border-spacing: 0 10px;
    .tableHead{
      
      .tableHeadRow{
        
        height: 60px;
        
        
        //padding: 20px;
        //margin-bottom: 10px;
        
        .tableHeadCell{
          font-size: 16px;
          font-weight: 600;
          line-height: 19.09px;
          letter-spacing: 0.02em;
          text-align: left;
          color: #96A8B8;
          background: #FFFFFF;
          border: 0;
          padding: 20px 25px
        }
        
        .tableHeadCellFirst{
          border-bottom-left-radius: 10px;
          border-top-left-radius: 10px;
          padding: 20px 25px 20px 20px;
        }
        
        .tableHeadCellLast{
          border-bottom-right-radius: 10px;
          border-top-right-radius: 10px;
          padding: 20px 20px 20px 25px;
        }
        
        .statusColor{
        
        }
      }
      
      
    }
    
    .tableBody{
      
      .tableBodyRow{
        gap: 10px;
        height: 60px;
        
        
        .tableBodyCell{
          height: 60px;
          font-size: 16px;
          font-weight: 600;
          line-height: 19.09px;
          letter-spacing: 0.02em;
          color: #262626;
          padding: 0 25px;
          background: #FFFFFF;
        }
        
        .tableBodyCellFirst{
          border-bottom-left-radius: 10px;
          border-top-left-radius: 10px;
          padding: 0 25px 0 20px;
        }
        
        .tableBodyCellLast{
          border-bottom-right-radius: 10px;
          border-top-right-radius: 10px;
          padding: 0 20px 0 25px;
        }
      }
      
      
    }
    
    
    
    
  }
}
.new{
  color: #FC2E20!important;
}
.work{
  color: #FC8621!important;
}
.done{
  color: #03A65A!important;
}

@media (max-width: 1439px) {
  .tableCellMobile{
    display: none!important;
  }
  .tableContainer {
    height: 100%;
  }
  .table{
    min-width: unset!important;
  }
  .tableHeadCell{
    padding: 20px 25px
  }
  .greenBtn{
    margin-left: auto;
    max-width: 170px;
  }
  .tableHeadCellFirst{
    padding: 20px;
    max-width: 120px;
  }
  
  .tableHeadCellLast{
    padding: 20px;
  }
  .tableBodyCell{
    padding: 0 20px;
  }
  .tableBodyCellFirst{
    padding: 0 20px 0 20px;
    max-width: 120px;
  }
  .tableBodyCellLast{
    padding: 0 20px 0 20px;
  }
}
