
            @import "app/styles/variables.scss";
            @import "app/styles/mixins.scss";
          

.offerInside{
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.offerWrapper{
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}
.appWrapper{
  padding: 20px;
  width: 100%;
  background: #FFFFFF;
  border-radius: 10px;
  height: 380px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 20px 60px;
  align-content: flex-start;
  justify-content: flex-start;
}
.offerBlock{
  padding: 20px;
  width: 100%;
  background: #FFFFFF;
  border-radius: 10px;
  height: 380px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 20px 60px;
  align-content: flex-start;
  justify-content: flex-start;
}
.appDesc{
  display: flex;
  flex-direction: column;
  gap: 4px;
  
}

.appDescTitle{
  color: #96A8B8;
  
  font-size: 14px;
  font-weight: 500;
  line-height: 16.48px;
  letter-spacing: 0.02em;
  
}
.order{
  color: #FC8621!important;
}
.stock{
  color: #03A65A!important;
}

.appDescValue{
  color: #001317;
  
  font-size: 16px;
  font-weight: 600;
  line-height: 19.09px;
  letter-spacing: 0.02em;
  
}

.header{
  font-size: 16px;
  font-weight: 600;
  line-height: 19.09px;
  letter-spacing: 0.02em;
  color: #262626;
  
}


.offerBlock{
  background: #FFFFFF;
  border-radius: 10px;
  padding: 20px;
}
@media (max-width: 1439px) {
  
  .offerInside{
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .offerWrapper{
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }
  .appWrapper{
    padding: 16px;
    width: 100%;
    background: #FFFFFF;
    border-radius: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 16px;
  }
  .offerBlock{
    padding: 16px;
    width: 100%;
    background: #FFFFFF;
    border-radius: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 16px;
  }
  .appDesc{
    display: flex;
    flex-direction: column;
    gap: 4px;
    
  }
  
  .appDescTitle{
    color: #96A8B8;
    
    font-size: 14px;
    font-weight: 500;
    line-height: 16.48px;
    letter-spacing: 0.02em;
    
  }
  .order{
    color: #FC8621!important;
  }
  .stock{
    color: #03A65A!important;
  }
  
  .appDescValue{
    color: #001317;
    
    font-size: 16px;
    font-weight: 600;
    line-height: 19.09px;
    letter-spacing: 0.02em;
    
  }
  
  .header{
    font-size: 16px;
    font-weight: 600;
    line-height: 19.09px;
    letter-spacing: 0.02em;
    color: #262626;
    
  }

  .offerBlock{
    background: #FFFFFF;
    border-radius: 10px;
    padding: 16px;
  }
  
}
