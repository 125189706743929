
            @import "app/styles/variables.scss";
            @import "app/styles/mixins.scss";
          
.leftBar{
  max-width: 300px;
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  height: 95vh;
  display: flex;
  flex-direction: column;
  gap: 36px;
  
  > a {
    padding: 10px;
  }
}
@media (max-width: 1439px) {
  .leftBar{
    max-width: 700px;
    width: 100%;
    border-radius: 10px;
    padding: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    > a {
      padding: 0;
      img {
        width: 160px;
        
      }
    }
  }
  
}
