
            @import "app/styles/variables.scss";
            @import "app/styles/mixins.scss";
          
.downloadBtn{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  gap: 8px;
  background: #88181833;
  border-radius: 10px;
  height: 40px;
  cursor: pointer;
  width: 171px;
  margin-top: 10px;
  
  > span {
    font-size: 12px;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 0.02em;
    color: #881818;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.downBtnIcon{
  margin-left: auto;
}
