
            @import "app/styles/variables.scss";
            @import "app/styles/mixins.scss";
          
.calcContainer{
  
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  
  .title{
    font-size: 24px;
    font-weight: 600;
    line-height: 28.63px;
    letter-spacing: 0.02em;
    width: 100%;
  }
  
  .big{
    width: 100%;
    max-width: 500px;
  }
  
  .small{
    width: 100%;
    max-width: 240px;
  }
  
  .bio, .email, .tel, .condition, .inn, .payment, .quantity, .ts, .brand, .model, .file, .submitBtn{
    height: 100%;
    max-height: 50px;
  }
  
  .bio{}
  
  .email{}
  
  .tel{}
  
  .condition{
    gap: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .inn{}
  
  .payment{}
  
  .quantity{}
  
  .ts{}
  
  .brand{}
  
  .model{}
  
  .slider{
    height: 100%;
    max-height: 120px;
  }
  
  .file{}
  
  .submitBtn{}
}
.condition_err{
  color: #FFC5C5!important;
}

.modalButtonWrapper{
  display: flex!important;
  flex-direction: row!important;
  justify-content: center!important;
}
.modalButton{
  max-width: 245px!important;
}
.modalTitle{
  display: flex!important;
  flex-direction: row!important;
  justify-content: center!important;
  margin-bottom: 12px;
  > span {
    font-size: 22px!important;
    font-weight: 600!important;
    line-height: 26.25px!important;
    text-align: center!important;
    color: #001317!important;
  }
}

.modalDescription{
  display: flex!important;
  flex-direction: row!important;
  justify-content: center!important;
  margin-bottom: 14px;
  > span {
    font-size: 16px!important;
    font-weight: 600!important;
    line-height: 19.09px!important;
    text-align: center!important;
    color: #001317!important;
  }
}

@media (max-width: 1439px) {
  .calcContainer{
    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
    align-items: center;
  
  
    .title{
      font-size: 20px;
      font-weight: 600;
      line-height: 23.86px;
      letter-spacing: 0.02em;

      width: 100%;
    }
  
    .big{
      width: 100%!important;
      max-width: none;
    }
  
    .small{
      width: 100%!important;
      max-width: none;
    }
    .condition{
      margin: 8px 0;
    }
    
    .submitBtn{
      margin: 8px 0 0 0;
    }
    .file{
      margin: 38px 0 0 0;
    }
  
    .bio, .email, .tel, .condition, .inn, .payment, .quantity, .ts, .brand, .model, .file, .submitBtn{
      height: 100%;
      max-height: 50px;
    }
  }
}
