
            @import "app/styles/variables.scss";
            @import "app/styles/mixins.scss";
          
.authContainer{
  background: #FFFFFF;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
