
            @import "app/styles/variables.scss";
            @import "app/styles/mixins.scss";
          
.appContainer{
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (max-width: 1439px) {
  .appContainer{
    padding: 16px!important;

  }
}
.notFoundWrapper{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1060px;
  gap: 92px;
  align-items: center;
  margin: 0 auto;
}

.notFoundDesc{
  display: flex;
  flex-direction: column;
  h1{
    font-size: 40px;
    font-weight: 700;
    line-height: 48.36px;
    margin-bottom: 12px;
  }
  h3{
    font-size: 24px;
    font-weight: 600;
    line-height: 28.63px;
    margin-bottom: 32px;
  }
  p{
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.04em;
    margin-bottom: 85px;
  }
}

.notFoundImg{
  max-width: 525px;
  width: 100%;
  height: auto;
  img {
    width: 100%;
    height: auto;
  }
}
.btn{
  max-width: 247px;
}
