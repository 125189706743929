
            @import "app/styles/variables.scss";
            @import "app/styles/mixins.scss";
          
.loginPage{
  display: flex;
  flex-direction: column;
  gap: 64px;
  max-width: 496px;
  
  .loginTitleBlock{
    display: flex;
    flex-direction: column;
    gap: 24px;
    
    .loginTitle{
      font-size: 36px;
      font-weight: 700;
      line-height: 44px;
      
      .loginTitleBlack{
        color: #001317;
      }
    }
    
    .loginDesc{
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: 0.15000000596046448px;
      
      color: #96A8B8;
    }
    .descBlue{
      color: #4794EF;
    }
  }
  
  .loginForm{
    display: flex;
    flex-direction: column;
    gap: 48px;
    
    .loginInputBlock{
      display: flex;
      flex-direction: row;
      gap: 44px;
      
      //.otpInputBack{
      //  background: #ECF3F6;
      //  height: 50px;
      //  border-radius: 8px;
      //  display: flex;
      //  align-items: center;
      //  justify-content: center;
      //  padding: 2px;
      //}
      .otpInputBack{}
    }
  }
  .otpInput{
  
  }
  
  .otpButtonBlock{
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
    .otpBtn{
      color: #96A8B8;
      text-decoration: none;
      cursor: pointer;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.15000000596046448px;
    }
  }
  
}

.modalButtonWrapper{
  display: flex!important;
  flex-direction: row!important;
  justify-content: center!important;
}
.modalButton{
  max-width: 245px!important;
  
}
.modalTitle{
  display: flex!important;
  flex-direction: row!important;
  justify-content: center!important;
  margin-bottom: 12px;
  > span {
    font-size: 22px!important;
    font-weight: 600!important;
    line-height: 26.25px!important;
    text-align: center!important;
    color: #001317!important;
  }
  
  
}

.modalDescription{
  display: flex!important;
  flex-direction: row!important;
  justify-content: center!important;
  margin-bottom: 14px;
  > span {
    font-size: 16px!important;
    font-weight: 600!important;
    line-height: 19.09px!important;
    text-align: center!important;
    color: #001317!important;
  }
  
  
}

@media (max-width: 1439px) {
  
  .loginPage{
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: 700px;
    
    .loginTitleBlock{
      display: flex;
      flex-direction: column;
      gap: 8px;
      
      .loginTitle{
        font-size: 24px;
        font-weight: 700;
        line-height: 44px;
        
        .loginTitleBlack{
          color: #001317;
        }
      }
      
      .loginDesc{
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0.15000000596046448px;
        
        color: #96A8B8;
      }
      .descBlue{
        color: #4794EF;
      }
    }
    
    .loginForm{
      display: flex;
      flex-direction: column;
      gap: 24px;
      
      .loginInputBlock{
        display: flex;
        flex-direction: row;
        gap: 16px;
        justify-content: center;
        
        //.otpInputBack{
        //  background: #ECF3F6;
        //  height: 50px;
        //  border-radius: 8px;
        //  display: flex;
        //  align-items: center;
        //  justify-content: center;
        //  padding: 2px;
        //}
        .otpInputBack{}
      }
    }
    .otpInput{
      
      gap: 16px!important;
    
    }
    
    .otpButtonBlock{
      height: 48px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      
      .otpBtn{
        color: #96A8B8;
        text-decoration: none;
        cursor: pointer;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.15000000596046448px;
      }
    }
    
  }
}
