
            @import "app/styles/variables.scss";
            @import "app/styles/mixins.scss";
          
.customBtn{
  border-radius: 10px;
  background-color: #4794EF;
  font-size: 18px;
  line-height: 20px;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 15px 20px;
  color: #EEF2F4;
  text-transform: none;
  box-shadow: none;
  &:hover{
    background-color: #2D74E3;
    box-shadow: none;
  }
  &:active, &:focus{
    background-color: #2460D1;
    box-shadow: none;
  };
  &:disabled{
    background-color: #C1E0FC;
    box-shadow: none;
  }
}
