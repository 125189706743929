
            @import "app/styles/variables.scss";
            @import "app/styles/mixins.scss";
          
.appContainer{
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (max-width: 1439px) {
  .appContainer{
    padding: 16px!important;

  }
  
}
