
            @import "app/styles/variables.scss";
            @import "app/styles/mixins.scss";
          
.headerContainer{
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  padding: 0 30px;
  border-radius: 10px;
  height: 100px;
  align-items: center;
  justify-content: space-between;
}
.navbarWrapper{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 132px;
}
.navbar{
  display: flex;
  flex-direction: row;
  gap: 62px;
  align-items: center;
}
.navbarLink{
  line-height: 23.86px;
  letter-spacing: 0.02em;
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
  color: #001317;
  transition: color .3s ease;
  
  &:hover, &:focus{
    color: #373C42;
  }
  
  &:active{
    color: #3E454C;
  }
}
.loginLink{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  padding: 9px 16px;
  background-color: #4794EF;
  transition: background-color .3s ease;
  border-radius: 10px;
  gap: 8px;
  
  &:hover, &:focus{
    background-color: #2D74E3;
  }
  &:active{
    background-color: #2460D1;
  }
  .loginIcon{
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  span{
    font-size: 18px;
    font-weight: 600;
    color: #EEF2F4;
    line-height: 21.47px;
    letter-spacing: 0.02em;
  }
}
.mobileHamburger{
  display: none;
}

.mobileMenu{
  opacity: 0;
  overflow: hidden;
  height: 0;
  
}
.navbarMobile{
  max-width: 700px;
  margin: 0 auto;
}
.mobileMenuLink{
  margin: 0 auto;
}
.open{}
@media (max-width: 1439px) {
  
  .navbarWrapper{
    display: none;
  }
  .mobileHamburger{
    display: flex;
  }
  .headerContainer{
    min-width: 380px;
    width: 100%;
    background: none;
    max-width: 700px;
    padding: 0 16px;
    height: 45px;
    margin: 0 auto;
  }
  .mobileMenu.open{
    opacity: 1;
    overflow: visible;
    height: 220px;
    margin-top: 20px;
  }
  .navbarMobile{
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
  }
  .mobileMenuLink{
    line-height: 21.86px;
    width: 100%;
    letter-spacing: 0.02em;
    font-size: 18px;
    font-weight: 600;
    text-decoration: none;
    color: #001317;
    transition: color .3s ease;
    background: #FFFFFF;
    padding: 8px 16px;
    border-radius: 10px;
  }
  .loginLink{
    max-width: 700px;
    margin: 8px auto 0 auto;
    justify-content: center;
  }
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  

}
.svgIcon{
  svg > path {
    fill: #1C1B1F;
  }
}
