
            @import "app/styles/variables.scss";
            @import "app/styles/mixins.scss";
          
.textInput{

}
.svgIcon{
  svg {
    fill: none;
  }
}

