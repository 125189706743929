
            @import "app/styles/variables.scss";
            @import "app/styles/mixins.scss";
          
.footerContainer{
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  padding: 0 30px;
  border-radius: 10px;
  height: 100px;
  align-items: center;
  justify-content: space-between;
  h3{
    font-size: 40px;
    font-weight: 700;
    line-height: 48.36px;
    letter-spacing: 0.02em;
  }
  span{
    font-size: 24px;
    font-weight: 600;
    line-height: 28.63px;
    letter-spacing: 0.02em;
  }
}
@media (max-width: 1439px) {
  .footerContainer{
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    background-color: #ffffff;
    flex-wrap: wrap;
    gap: 20px;
    padding: 16px;
    border-radius: 10px;
    height: 100px;
    align-items: center;
    justify-content: space-between;
    h3{
      font-size: 24px;
      font-weight: 700;
      line-height: 29.02px;
      letter-spacing: 0.02em;
    }
    span{

      font-size: 20px;
      font-weight: 600;
      line-height: 23.86px;
      letter-spacing: 0.02em;


    }
  }
}
