
            @import "app/styles/variables.scss";
            @import "app/styles/mixins.scss";
          
.newAppCalc{
  display: flex;
  flex-direction: column;
  gap: 20px;
  .calcBox{
    max-width: 540px;
    border-radius: 10px;
    padding: 20px;
    background: #FFFFFF;
  }
  
}
