
            @import "app/styles/variables.scss";
            @import "app/styles/mixins.scss";
          
.dashboardContainer{
  display: flex;
  flex-direction: row;
  gap: 20px;
}
@media (max-width: 1439px) {
  .dashboardContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 700px!important;
    min-height: unset!important;
    padding: 16px!important;
  }
}
