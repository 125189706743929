
            @import "app/styles/variables.scss";
            @import "app/styles/mixins.scss";
          
.openApp{
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}
.appWrapper{
  padding: 20px;
  width: 100%;
  background: #FFFFFF;
  border-radius: 10px;
  height: 380px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 20px 60px;
  align-content: flex-start;
  justify-content: flex-start;
}

.appDesc{
  display: flex;
  flex-direction: column;
  gap: 4px;
  
}

.appDescTitle{
  color: #96A8B8;
  
  font-size: 14px;
  font-weight: 500;
  line-height: 16.48px;
  letter-spacing: 0.02em;
  
}


.appDescValue{
  color: #001317;
  
  font-size: 16px;
  font-weight: 600;
  line-height: 19.09px;
  letter-spacing: 0.02em;
  
}

.offerHeader{
  
  font-size: 16px;
  font-weight: 600;
  line-height: 19.09px;
  letter-spacing: 0.02em;
  color: #262626;
  
}
.offerTableBlock{
  display: flex;
  flex-direction: column;
  gap: 20px;
}





.tableContainer{
  
  
  .table{
    border-collapse: separate;
    border-spacing: 0 10px;
    .tableHead{
      
      .tableHeadRow{
        
        height: 60px;
        
        
        //padding: 20px;
        //margin-bottom: 10px;
        
        .tableHeadCell{
          font-size: 16px;
          font-weight: 600;
          line-height: 19.09px;
          letter-spacing: 0.02em;
          text-align: left;
          color: #96A8B8;
          background: #FFFFFF;
          border: 0;
          padding: 20px 25px
        }
        
        .tableHeadCellFirst{
          border-bottom-left-radius: 10px;
          border-top-left-radius: 10px;
          padding: 20px 25px 20px 20px;
        }
        
        .tableHeadCellLast{
          border-bottom-right-radius: 10px;
          border-top-right-radius: 10px;
          padding: 20px 20px 20px 25px;
        }
        
        .statusColor{
        
        }
      }
      
      
    }
    
    .tableBody{
      
      .tableBodyRow{
        gap: 10px;
        height: 60px;
        
        
        .tableBodyCell{
          height: 60px;
          font-size: 16px;
          font-weight: 600;
          line-height: 19.09px;
          letter-spacing: 0.02em;
          color: #262626;
          padding: 0 25px;
          background: #FFFFFF;
        }
        
        .tableBodyCellFirst{
          border-bottom-left-radius: 10px;
          border-top-left-radius: 10px;
          padding: 0 25px 0 20px;
        }
        
        .tableBodyCellLast{
          border-bottom-right-radius: 10px;
          border-top-right-radius: 10px;
          padding: 0 20px 0 25px;
        }
        
        &.selectedRow {
  
          .tableBodyCell{
            color: #FFFFFF;
            background: #03A65A;
          }
          .svgIcon{
            background: #FFFFFF!important;
          }
          svg > path {
            fill: #03A65A;
          }
        }
        
        
      }
      
      
    }
    
    
    
    
  }
}
.svgIcon{
  background: #4794EF!important;
  border-radius: 10px!important;
}


@media (max-width: 900px) {
  .tableCellMobile{
    display: none!important;
  }
}
@media (max-width: 1439px) {
  
  .openApp{
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }
  .appWrapper{
    padding: 16px;
    width: 100%;
    background: #FFFFFF;
    border-radius: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 16px;
  }
  
  .appDesc{
    display: flex;
    flex-direction: column;
    gap: 4px;
    
  }
  
  .appDescTitle{
    color: #96A8B8;
    
    font-size: 14px;
    font-weight: 500;
    line-height: 16.48px;
    letter-spacing: 0.02em;
    
  }
  
  
  .appDescValue{
    color: #001317;
    
    font-size: 16px;
    font-weight: 600;
    line-height: 19.09px;
    letter-spacing: 0.02em;
    
  }
  
  .offerHeader{
    
    font-size: 16px;
    font-weight: 600;
    line-height: 19.09px;
    letter-spacing: 0.02em;
    color: #262626;
    
  }
  .offerTableBlock{
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  
  .tableContainer {
    height: 100%;
  }
  .table{
    min-width: unset!important;
  }
  .tableHeadCell{
    padding: 20px;
  }
  .tableHeadCellFirst{
    padding: 20px;
    max-width: 120px;
  }
  
  .tableHeadCellLast{
    padding: 20px;
  }
  .tableBodyCell{
    padding: 0 20px;
  }
  .tableBodyCellFirst{
    padding: 0 20px 0 20px;
    max-width: 120px;
  }
  .tableBodyCellLast{
    padding: 0 20px 0 20px;
  }
  .tableBodyCell{
    font-size: 14px;
  }
}
