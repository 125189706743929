
            @import "app/styles/variables.scss";
            @import "app/styles/mixins.scss";
          
.customFileInput{
  border-radius: 4px!important;
  background-color: #FFFFFF!important;
  font-size: 18px!important;
  line-height: 21px!important;
  font-weight: 500!important;
  display: flex!important;
  flex-direction: row!important;
  align-items: center!important;
  justify-content: center!important;
  padding: 15px 20px!important;
  color: #4794EF!important;
  border: 1px dashed #4794EF!important;
  text-transform: none!important;
  box-shadow: none!important;

  &:hover{
    color: #2D74E3!important;
    border-color: #2D74E3!important;
    box-shadow: none!important;
  }
  &:active, &:focus{
    color: #2460D1!important;
    border-color: #2460D1!important;
    box-shadow: none!important;
  };
  &:disabled{
    color: #C1E0FC!important;
    border-color: #C1E0FC!important;
    box-shadow: none!important;
  }
}
.text{
  white-space: nowrap!important;
  overflow: hidden!important;
  text-overflow: ellipsis!important;
}
