
            @import "app/styles/variables.scss";
            @import "app/styles/mixins.scss";
          
.titleUp{
  font-size: 14px;
  font-weight: 500;
  line-height: 16.48px;
  letter-spacing: 0.02em;
  color: #96A8B8;
  margin-bottom: 14px;
}
.valueTitle{
  font-size: 16px;
  font-weight: 600;
  line-height: 19.09px;
  letter-spacing: 0.02em;
  margin-bottom: 11px;
}
.sliderWrapper{
  background-color: #ECF3F6;
  border-radius: 10px;
  max-height: 120px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
}
.sliderCustom{}
.sliderContainer{}
@media (max-width: 1439px) {
  .titleUp{
    font-size: 12px;
    font-weight: 500;
    line-height: 14.12px;
    letter-spacing: 0.02em;
    color: #96A8B8;
    margin-bottom: 14px;
  }
  .valueTitle{
    font-size: 16px;
    font-weight: 600;
    line-height: 19.09px;
    letter-spacing: 0.02em;
    margin-bottom: 11px;
  }
  .sliderWrapper{
    background-color: #ECF3F6;
    border-radius: 10px;
    max-height: none;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 8px 16px;
  }
}
