
            @import "app/styles/variables.scss";
            @import "app/styles/mixins.scss";
          
.authRightBar{
  height: 100vh;
  max-height: 100vh;
  //border-radius: 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  .authBackground{
    width: auto;
    height: 100%;
    border-bottom-left-radius: 24px;
    border-top-left-radius: 24px;
  }
}
@media (max-width: 1439px) {
  .authRightBar{
    display: none;
  }
}
