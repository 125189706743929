
            @import "app/styles/variables.scss";
            @import "app/styles/mixins.scss";
          
.selectInput{
  //.MuiSelect-select{
  //  width: 160px;
  //  white-space: nowrap;
  //  overflow: hidden;
  //  text-overflow: ellipsis;
  //  display: block;
  //}
}
.placeholder{
  color: #001317;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.19px;
  letter-spacing: 0.02em;
  
}
.dropdownStyle{
  border-radius: 10px;
  background-color: #ECF3F6;
  border: 1px solid #73ADBD;
  margin-top: -5px;
  box-shadow: none;
  
  .MuiList-root{
    background-color: #ECF3F6;
  
    .MuiMenuItem-root{
      color: #001317;
      font-size: 18px;
      font-weight: 500;
      line-height: 21.19px;
      letter-spacing: 0.02em;
      padding: 15px 20px;
      background-color: unset;
    }
  }
  
}
.MuiPopover-paper{
  background: #ECF3F6!important;
}
