
            @import "app/styles/variables.scss";
            @import "app/styles/mixins.scss";
          
.authLeftBar{
  padding: 48px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  .Logo{
    width: 100%;
    margin-bottom: auto;
  }
  .authForm{
    align-self: center;
  }
  .authFooter{
    margin-top: auto;
  
    .authFooterText{
      display: flex;
      flex-direction: row;

      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.15000000596046448px;
      
      color: #96A8B8;
    }
    .authLink{
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.15000000596046448px;
      text-decoration: none;
      color: #4794EF;
    }
  }
}
.agentFooter{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  
  > span {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.15000000596046448px;
    color: #96A8B8;
  }
}
.agentFooterLink{
  cursor: pointer;
}
@media (max-width: 1439px) {
  .authLeftBar{
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    .Logo{
      width: 100%;
      margin-bottom: 20px;
    }
    .authForm{
      align-self: center;
    }
    .authFooter{
      margin-top: 20px;
      
      .authFooterText{
        display: flex;
        flex-direction: row;
        
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.15000000596046448px;
        
        color: #96A8B8;
      }
      .authLink{
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.15000000596046448px;
        text-decoration: none;
        color: #4794EF;
      }
    }
  }
  .agentFooter{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
    > span {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.15000000596046448px;
      color: #96A8B8;
    }
  }
  .agentFooterLink{
    cursor: pointer;
  }
}
