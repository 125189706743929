
            @import "app/styles/variables.scss";
            @import "app/styles/mixins.scss";
          
.leftBar{
  max-width: 300px;
  width: 100%;
  background: #FFFFFF;
  border-radius: 10px;
  padding: 10px;
  height: 95vh;
  display: flex;
  flex-direction: column;
  gap: 36px;
  
  > a {
    padding: 10px;
  }

  .agentsLink{
    background: #ECF3F6;
    border-radius: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 13px 20px;
    gap: 16px;
    cursor: pointer;
    transition: background-color .3s ease;
    &:hover {
      background: #C1E0FC;
    }
  
    .spanText{
      font-size: 16px;
      font-weight: 600;
      line-height: 19.2px;
      letter-spacing: 0.02em;
      color: #262626;
    
    }
  }
  .userBlock{
    margin-top: auto;
    background: #ECF3F6;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
    .userInfo{
      display: flex;
      flex-direction: column;
      gap: 4px;
  
      .userName{
        font-size: 16px;
        font-weight: 600;
        line-height: 19.2px;
        letter-spacing: 0.02em;
        color: #262626;
      
      }
      .userMail{
        color: #96A8B8;
        font-size: 14px;
        font-weight: 600;
        line-height: 16.8px;
        letter-spacing: 0.02em;
      }
    }
    
    .logoutBtn{
      //width: 20px;
      //height: 20px;
  
      .svgLogout{
    
      }
    }
    
  }
}


@media (max-width: 1439px) {
  
  .leftBar{
    max-width: 700px;
    width: 100%;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 10px;
    height: 64px;
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    align-items: center;
    gap: 16px;
    
    > a {
      margin-right: auto;
      padding: 10px 8px;
      > img {
        width: 160px;
      }
      
    }
    
    .agentsLink{
      
      background: #ECF3F6;
      border-radius: 10px;
      width: 48px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 14px;
      gap: 16px;
      cursor: pointer;
      transition: background-color .3s ease;
      &:hover {
        background: #C1E0FC;
      }
      
      .spanText{
        display: none;
        
      }
    }
    .userBlock{
      margin-top: unset;
      background: #ECF3F6;
      border-radius: 10px;
      width: 48px;
      padding: 14px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      
      .userInfo{
        display: none;
      }
      
      .logoutBtn{
        //width: 20px;
        //height: 20px;
        
        .svgLogout{
        
        }
      }
      
    }
  }
}
