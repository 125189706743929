
            @import "app/styles/variables.scss";
            @import "app/styles/mixins.scss";
          
.headerLink{
  display: flex;
  flex-direction: row;
  gap: 35px;
  align-items: center;
  
  .svgIcon{
    border-radius: 10px;
    background: #FFFFFF;
  }
  
  .headerTitle{
    font-size: 16px;
    font-weight: 600;
    line-height: 19.09px;
    letter-spacing: 0.02em;
    color: #262626;
    
  }
}
