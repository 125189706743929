
            @import "app/styles/variables.scss";
            @import "app/styles/mixins.scss";
          
.loginPage{
  display: flex;
  flex-direction: column;
  gap: 64px;
  max-width: 676px;
  
  .loginTitleBlock{
    display: flex;
    flex-direction: column;
    gap: 24px;
    
    .loginTitle{
      font-size: 32px;
      font-weight: 500;
      line-height: 40px;
      justify-content: center;
      display: flex;
      .loginTitleBlack{
        color: #001317;
      }
    }
    
    .loginDesc{
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: 0.15000000596046448px;
      text-align: center;
      
      color: #96A8B8;
    }
  }
  
  .loginForm{
    display: flex;
    flex-direction: column;
    gap: 48px;
    

    
    .accept{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      
      .rememberLink{
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.15000000596046448px;
        
        color: #4794EF;
        text-decoration: none;
      }
    }
    .accept_err{
      color: #FFC5C5;
    }
    
  }
  .grayRules{
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.15000000596046448px;
    color: #96A8B8;
    display: flex;
    flex-direction: row;
  }
  .blueRules{
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.15000000596046448px;
    color: #4794EF;
    cursor: pointer;
    margin-left: 6px;
  }
  
  .submitBtn{
    height: 48px;
  }
  .agentRegForm{
   display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;
  }
  .inputWrapper{
    max-width: 326px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .inputLabel{
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.15000000596046448px;
    color: #96A8B8;
  }
  .agentInput{
    .MuiOutlinedInput-notchedOutline{
      border: 1px solid #B5D4F9!important;
    }
  }
}
.blackBtn button{
  background-color: #1A1D21;
}
.blackBtn:hover button{
  background-color: #313138;
}
.btnBlock{
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
}
.spanBtnBlock{
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.15000000596046448px;
  color: #686B6E;

}
.goodRegInfo{
  display: flex;
  flex-direction: column;
  gap: 64px;
}

@media (max-width: 1439px) {
  
  .loginPage{
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: 700px;
    
    .loginTitleBlock{
      display: flex;
      flex-direction: column;
      gap: 12px;
      
      .loginTitle{
        font-size: 24px;
        font-weight: 500;
        line-height: 40px;
        text-align: center;
        
        .loginTitleBlack{
          color: #001317;
        }
      }
      
      .loginDesc{
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0.15000000596046448px;
        text-align: center;
        
        color: #96A8B8;
      }
    }
    
    .loginForm{
      display: flex;
      flex-direction: column;
      gap: 24px;
      
      
      
      .accept{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        
        .rememberLink{
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0.15000000596046448px;
          
          color: #4794EF;
          text-decoration: none;
        }
      }
      
    }
    .grayRules{
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.15000000596046448px;
      color: #96A8B8;
      display: flex;
      flex-direction: row;
    }
    .blueRules{
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.15000000596046448px;
      color: #4794EF;
      cursor: pointer;
      margin-left: 6px;
    }
    
    .submitBtn{
      height: 48px;
    }
    .agentRegForm{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 24px;
    }
    .inputWrapper{
      max-width: 700px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
    .inputLabel{
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.15000000596046448px;
      color: #96A8B8;
    }
    .agentInput{
      .MuiOutlinedInput-notchedOutline{
        border: 1px solid #B5D4F9!important;
      }
    }
  }
  .btnBlock{
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
  }
  .spanBtnBlock{
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.15000000596046448px;
    color: #686B6E;
    
  }
  .goodRegInfo{
    display: flex;
    flex-direction: column;
    gap: 64px;
  }
  
  
}
